import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function SectionIntro() {
  return (
    <>
      <div className="section section-dark">
        <Container>
          <Row>
            <div className="title">
              <h1>Nighttime Drive</h1>
            </div>
          </Row>
          <Row>
            <span className="description">
              <h3>
                Nighttime drive is a cover band in the central PA area. The band
                plays a diverse set of songs with genres like classic rock,
                country, current pop, and much more.
              </h3>
            </span>
          </Row>
          <Row>
            <span className="description">
              <h3>
                Check out our upcoming shows.{" "}
                <i>Press play and go for a ride!</i>
              </h3>
            </span>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionIntro;
