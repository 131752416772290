import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";

// core components

function Show(props) {
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle><h3>{props.showheader}</h3></CardTitle>
          <CardTitle><h4><b>{props.showdate}</b></h4></CardTitle>
          <CardText>
            {props.showdetails}
          </CardText>
          <Button href={props.showlink} color="primary">
            Show Details
          </Button>
        </CardBody>
      </Card>
    </>
  );
}

export default Show;
