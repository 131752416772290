import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { Button, FormGroup, Container, Row, Col } from "reactstrap";

// core components

function SectionNTDFooter() {
  const [languageSelect, setLanguageSelect] = React.useState({
    value: "en",
    label: "English",
  });
  const [curencySelect, setCurencySelect] = React.useState({
    value: "USD",
    label: "USD",
  });
  return (
    <>
 

      <div className="separator" />
      <footer className="footer footer-black footer-big">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="4" sm="4">
              <div className="logo text-center">
                <h3>Nighttime Drive</h3>
              </div>
            </Col>
            <Col className="offset-md-2" md="6" sm="8">
              <div className="links">

                <div className="copyright">
                  <div className="pull-left">
                    © {new Date().getFullYear()}, Nighttime Drive Band
                  </div>
                  <div className="pull-right">
                   
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      
    </>
  );
}

export default SectionNTDFooter;
