import React from "react";
import emailjs from "emailjs-com";
import { FormGroup, Label, Input, FormText, Button, Modal } from "reactstrap";
import { Container, Row, Col, Alert } from "reactstrap";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      name: "NTD Website",
      email: "",
      contact: "Booking",
      submitted: false,
      modal: false,
      disableforms: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
      <div id="ntdcontact"></div>
        <Container>
          {this.state.submitted ? (
            <Row>
              <Col lg="6" md="12"><br /><br />
                <Alert color="success">
                  Thank you for your message.  We will get back to you as soon as we can!
                </Alert>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">Contact Us</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <form>
                <FormGroup>
                  <Label for="exampleEmail">Email address</Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Enter email"
                    value={this.state.email}
                    onChange={this.handleEmail}
                    disabled={this.state.disableforms}
                  />
                  <FormText color="muted">
                    We'll never share your email with anyone else.
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Label for="ddlContactReason">Contact Reason</Label>
                  <Input
                    type="select"
                    name="select"
                    id="ddlContactReason"
                    disabled={this.state.disableforms}
                  >
                    <option>Booking Inquiry</option>
                    <option>Fan Mail</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleText">Note</Label>
                  <Input
                    type="textarea"
                    name="text"
                    id="exampleText"
                    onChange={this.handleChange}
                    value={this.state.feedback}
                    disabled={this.state.disableforms}
                  />
                </FormGroup>
                <Button
                  color="primary"
                  type="submit"
                  onClick={this.handleSubmit}
                  disabled={this.state.disableforms}
                >
                  Submit
                </Button>
              </form>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal}>
          <Container>
            <Row>
              <Col>
                <div className="modal-header">Please wait</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="modal-body">
                  <h4 className="title">Submitting form. . .</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal>
      </>
    );
  }

  handleChange(event) {
    this.setState({ feedback: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modal: true, disableforms: true });

    const templateId = "NTDContact";
    //init("user_kfDqNZhiidQ0JdvdeLxa3");

    this.sendFeedback(templateId, {
      message: this.state.feedback,
      from_name: this.state.name,
      reply_to: this.state.email,
    });
  }

  sendFeedback(templateId, variables) {
    this.setState({ submitted: true });
    emailjs
      .send(
        "service_1wejd79",
        "template_7pkswfi",
        variables,
        "user_kfDqNZhiidQ0JdvdeLxa3"
      )
      .then(
        (result) => {
          console.log(result.text);
          this.setState({ submitted: true, modal: false });
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
}
