/* eslint-disable react/jsx-no-target-blank*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create an image gallery
import { Gallery, Item } from "react-photoswipe-gallery";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Modal,
  Input,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  CustomInput,
} from "reactstrap";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.js";

// PhotoSwipe items
const photoSwipeItems = [
  {
    src: require("assets/img/gallery/outfit-gucci.jpg").default,
    title: "Gucci",
    w: "750",
    h: "1002",
  },
  {
    src: require("assets/img/gallery/outfit-paul-smith.jpg").default,
    title: "Paul Smith",
    w: "750",
    h: "1002",
  },
  {
    src: require("assets/img/gallery/outfit-maison-margiela.jpg").default,
    title: "Maison Margiela",
    w: "750",
    h: "1002",
  },
  {
    src: require("assets/img/gallery/outfit-burberry.jpg").default,
    title: "Burberry",
    w: "750",
    h: "1002",
  },
  {
    src: require("assets/img/gallery/ressence.jpg").default,
    title: "",
    w: "960",
    h: "800",
  },
  {
    src: require("assets/img/gallery/gucci-sun.jpg").default,
    title: "",
    w: "960",
    h: "800",
  },
  {
    src: require("assets/img/gallery/bottega.jpg").default,
    title: "",
    w: "960",
    h: "800",
  },
  {
    src: require("assets/img/gallery/bracelet.jpg").default,
    title: "",
    w: "960",
    h: "800",
  },
];
// carousel items
const carouselItems = [
  {
    src: require("assets/img/gig2-1.jpg").default,
    altText: "Campbelltown",
    caption: "Campbelltown",
  },
  {
    src: require("assets/img/gig2-3.jpg").default,
    altText: "Campbelltown",
    caption: "Campbelltown",
  }
];

function SectionCarousel() {
  // modals states
  const [classic, setClassic] = React.useState(false);
  const [notice, setNotice] = React.useState(false);
  const [smallAlert, setSmallAlert] = React.useState(false);
  const [smallNotice, setSmallNotice] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [register, setRegister] = React.useState(false);
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  // carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section section-blue javascript-components">
        <Container>

          <div className="clearfix" />


          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Card className="card-raised page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={carouselItems}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {carouselItems.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <CarouselCaption
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionCarousel;
