import React from "react";
import Show from "../../components/Shows/Show";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function SectionShows() {
  return (
    <>
      <div className="section section-dark" id="ntdshows">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">Nighttime Drive Shows</h2>
            </Col>
          </Row>
          <Show showheader="Mountville Outdoor Community Event" 
            showdate="March 26th 2022" 
            showdetails="12:45PM - 2PM @ Froelich Park - Mountville"
            showlink="https://www.facebook.com/search/top?q=mj%20squad"
            />
            <Show showheader="Lancaster Outdoor Community Event" 
            showdate="June 11th 2022" 
            showdetails="10AM - 3PM @ Tanger Outlets - Lancaster"
            showlink="https://www.facebook.com/search/top?q=mj%20squad"
            />
            <Show showheader="Lancaster Outdoor Community Event" 
            showdate="September 17th 2022" 
            showdetails="Overlook Park - Lancaster"
            showlink="https://www.facebook.com/search/top?q=mj%20squad"
            />
        </Container>
      </div>
    </>
  );
}

export default SectionShows;
